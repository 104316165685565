'use client'

import Image from 'next/image'
import styled from 'styled-components'

import { spacing } from '@fortum/elemental-ui'

/**
 * Next Image wrapped in styled tag for NotFoud server component
 */

export const StyledImage = styled(Image)`
  width: 64rem;
  margin: 0 auto ${spacing.xs};
`
