'use client'

import { useEffect } from 'react'

import { sendGTMEvent } from '../utils/sendGTMEvent'

export const LogNotFound: React.FC = () => {
  useEffect(() => {
    sendGTMEvent({
      event: 'error_event',
      error_type: 'error_404',
    })
  }, [])

  return null
}
